<template>
    <div>
        <div v-if="waiting && profile_id" class="page-waiting">
            <loader size="lg" />
        </div>
        <div
            v-show="!waiting || !profile_id || !!login_request.panel_id"
            id="content"
            :class="{ narrow, 'guest-page': (!profile_id || !!login_request.panel_id) }"
            class="page"
        >
            <div v-if="status === 404">
                <slot name="not-found">
                    <div class="empty">
                        <h5 class="empty-title">{{ $root.translate("Please stay on the path!") }}</h5>
                        <p class="empty-subtitle">{{ $root.translate("Page not found") }}</p>
                    </div>
                </slot>
            </div>
            <authentication v-else-if="!!login_request.panel_id && (!profile_id || !walker_uuid)" />
            <div v-else-if="status === 401">
                <slot name="unauthorized">
                    <div class="empty">
                        <h5 class="empty-title">{{ $root.translate("That's kind of private...") }}</h5>
                        <p class="empty-subtitle">{{ $root.translate("Unauthorized") }}</p>
                    </div>
                </slot>
            </div>
            <div v-if="status === 200" :id="id" class="page-content">
                <slot name="title">
                    <header>
                        <base-link
                            :href="{ name: 'home' }"
                        >
                            <app-logo />
                        </base-link>
                    </header>
                </slot>
                <div class="page-body">
                    <slot></slot>
                </div>
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import { mapState } from "vuex"

import ui_utilities from "@/nibnut/mixins/UiUtilities"
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

import Authentication from "@/custom/dialogs/Authentication/Authentication"
import AppLogo from "@/nibnut/components/AppLogo"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import Loader from "@/custom/components/Loader"

export default {
    name: "Page",
    mixins: [addl_profile_utilities, ui_utilities],
    components: {
        Authentication,
        AppLogo,
        BaseLink,
        Loader
    },
    mounted () {
        this.load()
    },
    watch: {
        $route: "load",
        profile_id: "delayed_page_guard",
        walker_uuid: "delayed_page_guard"
    },
    methods: {
        load () {
            if(window.location.hash) {
                setTimeout(() => {
                    this.scroll_to_hash(window.location.hash)
                }, 250)
            }
            console.log("DEBUG PAGE LOAD", { profile_id: this.profile_id, walker_uuid: this.walker_uuid })
            this.page_guard()
        },
        page_guard () {
            console.log("DEBUG PAGE GARD", { id: this.id, meta: this.$route.meta, params: this.$route.params, profile_id: this.profile_id, profile: this.profile, search: window.location.search })
            if(this.$route.params && this.$route.params.panel_id && !this.profile_id) {
                console.log("DEBUG 1")
                this.login({ panel_id: this.$route.params.panel_id })
                this.$nextTick(() => this.$emit("statused", 307)) // temporary redirect
            } else if(!this.profile_id && window.location.search && window.location.search.match(/(\?|&)password-reset=/)) {
                console.log("DEBUG 2")
                this.login({ panel_id: "reset" })
                this.$nextTick(() => this.$emit("statused", 307)) // temporary redirect
            } else if(!this.profile_id && window.location.search && window.location.search.match(/(\?|&)invitation=/)) {
                console.log("DEBUG 3")
                this.login({ panel_id: "signup" })
                this.$nextTick(() => this.$emit("statused", 307)) // temporary redirect
            } else if(!!this.profile_id && !!this.$route.meta && (this.$route.meta.admin_route || this.$route.meta.regional_admin_route || this.$route.meta.login_required) && !this.walker_uuid) {
                console.log("DEBUG 4")
                if(this.profile.verify_info_on_next_login) this.login({ panel_id: "verification" })
                else this.login({ panel_id: "walkers" })
                this.$nextTick(() => this.$emit("statused", 307)) // temporary redirect
            } else if(this.$route.meta) {
                console.log("DEBUG 5")
                if(this.$route.meta.admin_route || this.$route.meta.regional_admin_route) {
                    const min_admin_role = this.$route.meta.regional_admin_route ? this.is_at_least_regional_admin : this.is_at_least_administrator
                    if(!this.profile_id || !min_admin_role) {
                        if(!this.profile_id) this.login()
                        this.$emit("statused", this.profile_id ? 401 : 307)
                    } else if(!!this.profile_id && min_admin_role) this.$emit("statused", 200)
                } else if(!!this.$route.meta.login_required && !this.profile_id) {
                    this.login({ panel_id: true })
                    this.$nextTick(() => this.$emit("statused", 307))
                } else this.additional_page_guards()
            } else this.additional_page_guards()
        },
        maybe_cancel_login () {
            if(!!this.login_request && !!this.login_request.panel_id) this.$store.dispatch("UNREQUEST_LOGIN")
        },
        additional_page_guards () {
            this.maybe_cancel_login()
            this.$emit("statused", 200)
        },
        delayed_page_guard () {
            setTimeout(() => {
                console.log("DEBUG PAGE DELAYED", { profile_id: this.profile_id, walker_uuid: this.walker_uuid })
                this.page_guard()
            }, 250)
        },
        login (data = {}) {
            this.$store.dispatch("REQUEST_LOGIN", data)
        },
        scroll_to_hash (hash) {
            // Make sure to set the target's class to nibnut-scroll-target so the scroll is padded for the site's header's height
            this.scroll_to(document.getElementById(hash.replace(/#/, "")))
        }
    },
    computed: {
        ...mapState(["login_request", "walker_uuid"])
    },
    props: {
        id: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: ""
        },
        waiting: {
            type: Boolean,
            default: false
        },
        narrow: {
            type: Boolean,
            default: false
        },
        status: {
            type: Number,
            default: 200
        },
        isRoot: {
            type: Boolean,
            default: false
        },
        backNavigationFallback: {
            type: Object, // { title, href: (string | object) }
            default: null
        }
    }
}
</script>

<style lang="scss">
@use "sass:math";
@use "sass:list";
@import "@/assets/sass/variables";

.page-waiting {
    width: 100vw;
    height: 100vh;

    & > .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
#guest-navbar {
    position: absolute;
    top: $layout-spacing-lg;
    right: Max(list.slash(calc(100vw - 980px), 2), $layout-spacing-lg);
    display: flex;
    gap: $layout-spacing;
}
.page {
    #guest-navbar,
    .guest-language-picker {
        display: none;
    }
    & > .page-content {
        & > header {
            position: relative;
            background-color: $brand-orange;
            padding: 0.25rem 0;
            text-align: center;

            &:before {
                content: " ";
                position: absolute;
                left: 0;
                bottom: 0;
                height: 153px;
                width: 232px;
                background: $brand-orange no-repeat bottom left url('~@/assets/img/Header_trees_left_desktop.svg');
                z-index: 0;
            }
            &:after {
                content: " ";
                position: absolute;
                right: 0;
                bottom: 0;
                height: 153px;
                width: 536px;
                background: $brand-orange no-repeat bottom right url('~@/assets/img/Header_trees_right_desktop.svg');
                z-index: 0;
            }

            & > a {
                position: relative;
                display: inline-block;
                z-index: 1;

                &, &:active, &:focus, &:visited, &:hover {
                    background: none;
                }
                img.img-responsive {
                    display: inline-block;
                    height: 5rem;
                    margin: 0 auto;
                }
            }
        }
        & > .page-body {
            padding: 1rem;

            section {
                margin-bottom: 1rem;
                h3 {
                    text-align: center;
                    margin-bottom: 1rem;

                    & > i {
                        display: block;
                        margin : 0 auto;
                    }
                }
            }
        }
    }
    &.guest-page {
        & > div:not(#user-editor) {
            .form-input {
                border-color: transparent;
            }
            .password-editor {
                .input-group-btn:last-child:not(:first-child) {
                    border-color: transparent;
                }
                .form-input-hint {
                    color: $brand-gray-dark;
                }
            }
        }
    }
    @media (max-width: $size-sm) {
        & > .page-content {
            & > header {
                background: $brand-orange no-repeat bottom left url('~@/assets/img/Header_trees.png');
                padding: 0.5rem 1rem;

                &:before,
                &:after {
                    display: none;
                }
                & > a img.img-responsive {
                    height: 4rem;
                    margin: 0;
                }
            }
            & > .page-body {
                padding: 2rem 0;
            }
        }
    }
}
</style>
